import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import NoPage from './pages/NoPage';
import RegisterPage from './pages/RegisterPage';
import PatientFormPage from './pages/PatientFormPage';
import PrivateTestPage from './pages/PrivateTestPage';
import RequireAuth from './components/RequireAuth';
import RolePage from './pages/RolePage';
import FilePage from './pages/FilePage';
import DashBoard from './pages/DashBoard';
import ForgotPage from './pages/ForgotPage';
import SettingPage from './pages/SettingPage';
import PatientPage from './pages/PatientPage';
import ResetPage from './pages/ResetPasswordPage';
//import PFPPCreation from './pages/PFPPCreationPage';
//import PFPPCreationModel from './components/exemples/PFPPCreationModel';
//import WeekCreationModel from './components/exemples/WeekCreationModel';
import DisplayPFPPPage from './pages/DisplayPFPPPage';
import DisplayPFPPDetails from './components/DisplayPFPPDetails';
import DisplayPFPPDetailsPage from './pages/DisplayPFPPDetailPage';

//if path is updated, don't forget to update in src/data/url.json, dev only
function App(props: any) {
    return (
        <BrowserRouter>
            <Routes>

                {/* public routes */}
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot" element={<ForgotPage/>}/>
                <Route path="/resetPassword" element={<ResetPage/>}/>
                <Route path="/register" element={<RegisterPage />} />
                <Route path='/file' element={<FilePage/>}></Route>


                {/* private routes for users */}
                <Route element={<RequireAuth roles={[2001]} />}>
                    <Route path="/private" element={<PrivateTestPage />} />
                    <Route path="/roles" element={<RolePage />} />
                    <Route path="/dashboard" element={<DashBoard/>} />
                    <Route path="/settings" element = {<SettingPage/>}/>
                    <Route path="/create_patient" element={<PatientFormPage />} />
                    <Route path="/patient_file" element={<PatientPage/>}/>
                    {/*<Route path="/create_pfpp" element={<PFPPCreation/>}/>*/}
                    {/*<Route path="/modelpfpp" element={<PFPPCreationModel/>}/>*/}
                    {/*<Route path="/modelweek" element={<WeekCreationModel/>}/>*/}
                    <Route path="/patient/:id/pfpp" element={<DisplayPFPPPage/>}/>
                    <Route path="/patient/pfpp/details" element={<DisplayPFPPDetailsPage/>}/>
                </Route>

                {/* private routes for admins */}
                <Route element={<RequireAuth roles={[5150]} />}>
                </Route>

                <Route path="*" element={<NoPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;