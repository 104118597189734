/**
 * @module AccountSettings
 * Forms to change the user's information such as email, name, password...
 */
import React, { useState } from 'react'
import { RootState } from '..'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';
import { login } from '../features/user';
import Address from '../models/addressModel';
import environmentVariables from '../config/environmentVariables';

const SETTING_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/changeValues`

function AccountSettings() {

    const user = useSelector((state: RootState) => state.user.userValue)

    const dispatch = useDispatch();

    const [pwd, setPwd] = useState<string[]>(["", ""]);
    const mail = useState<string>("");
    const spe = useState<string>("");
    const name = useState<string>("");
    const fName = useState<string>("");
    const phone = useState<string>("");
    const [contact, setContact] = useState<Boolean[]>([false, false, false]);

    const [streetNumber, setStreetNumber] = useState<string>("")
    const [streetName, setStreetName] = useState<string>("")
    const [zipCode, setZipCode] = useState<string>("")
    const [city, setCity] = useState<string>("")
    const [country, setCountry] = useState<string>("")

    //types need to be writed the SAME as userModel attributes
    const settings = [

        {
            "type": 'mail',
            "state": mail
        },
        {
            "type": 'spe',
            "state": spe
        },
        {
            "type": 'user',
            "state": name
        },
        {
            "type": 'fName',
            "state": fName
        },
        {
            "type": 'phone',
            "state": phone
        },


    ]


    /**
     * changes the state of contacts according to which checkbox is checked
     */
    function changeContact() {
        let tempContact = [false, false, false]
        if ((document.getElementById('adressContact') as HTMLInputElement)?.checked) {
            tempContact[0] = true;
        }
        if ((document.getElementById('phoneContact') as HTMLInputElement)?.checked) {
            tempContact[1] = true;
        }
        if ((document.getElementById('mailContact') as HTMLInputElement)?.checked) {
            tempContact[2] = true
        }
        setContact(tempContact)
    }

    /**
     * send the changes to backend
     * @param {string} type
     */
    
    async function changeProfil(type: string) {

        //depending on which type of info has been updated, we get the according state to send it to the backend
        let currentState: string | Boolean[] | String[] | Address = ""
        const setting = settings.filter(
            function (data) { return data.type == type });

        switch (type) {
            case 'address':
                currentState = {
                  streetNumber: streetNumber,
                  streetName: streetName,
                  zipCode: zipCode,
                  city: city,
                  country: country
                }
                break;
            case 'contact':
                currentState = contact;
                break;
            case 'pwd':
                currentState = pwd;
                break;
            default:
                currentState = setting[0].state[0];
                break;
        }


        try {
            const response = await axios.post(SETTING_URL,
                JSON.stringify({ user: user.user, type: type, state: currentState }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            console.log("Settings changed successfully")
            const {user : newUser, token : accessToken} = response?.data;
            

            //dispatch to redux session storage
            dispatch(login({ user : newUser, accessToken: accessToken }));

        }
        catch (error) {
            console.error("Erreur lors de la récupération des patients", error);
        }
    }




    //HTML form
    return (
        <div>
            {settings.map(setting => (
                <form key={setting.type} onSubmit={e => e.preventDefault()}>
                    <label htmlFor={setting.type}>New {setting.type}</label>
                    <input
                        type="text"
                        id={setting.type}
                        autoComplete="off"
                        placeholder={setting.type}
                        onChange={(e) => setting.state[1](e.target.value)}
                        value={setting.state[0]}
                        required
                    />
                    <input type="submit" value="Submit" onClick={() => changeProfil(setting.type)}></input>
                </form>
            ))}

            <form onSubmit={e => e.preventDefault()}>
                <label htmlFor='currentPwd'> Current Password</label>
                <input
                        type="text"
                        id={'currentPwd'}
                        autoComplete="off"
                        placeholder={"current Password"}
                        onChange={(e) => setPwd([e.target.value, pwd[1]])}
                        value={pwd[0]}
                        required
                    />
                <label htmlFor='newPwd'> New Password</label>
                <input
                        type="text"
                        id={'newPwd'}
                        autoComplete="off"
                        placeholder={"New Password"}
                        onChange={(e) => setPwd([pwd[0],e.target.value])}
                        value={pwd[1]}
                        required
                    />
                    <input type="submit" value="Submit" onClick={() => changeProfil("pwd")}></input>
            </form>

            <form key="address" onSubmit={e => e.preventDefault()}>
            <label htmlFor="address">New Address</label>
            <div>
              <input
                type="text"
                id="number"
                autoComplete="off"
                placeholder="Street Number"
                onChange={(e) => setStreetNumber(e.target.value)}
                value={streetNumber}
                required
              />
            </div>

            <div>
              <input
                type="text"
                id="name"
                autoComplete="off"
                placeholder="Street Name"
                onChange={(e) => setStreetName(e.target.value)}
                value={streetName}
                required
              />
            </div>

            <div>
              <input
                type="text"
                id="zip"
                autoComplete="off"
                placeholder="Zip Code"
                onChange={(e) => setZipCode(e.target.value)}
                value={zipCode}
                required
              />
            </div>

            <div>
              <input
                type="text"
                id="city"
                autoComplete="off"
                placeholder="City"
                onChange={(e) => setCity(e.target.value)}
                value={city}
                required
              />
            </div>

            <div>
              <input
                type="text"
                id="country"
                autoComplete="off"
                placeholder="Country"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
                required
              />
            </div>
            <input type="submit" value="Submit" onClick={() => changeProfil("address")}></input>
          </form>


            <form onSubmit={e => e.preventDefault()}>
                <label htmlFor='contact'> Contact settings</label>
                <label htmlFor='adressContact'>Allow adress contact</label>
                <input type="checkbox"
                    id="adressContact"
                    name="contacts"
                    value="adress"
                    onChange={(e) => changeContact()}

                />
                <label htmlFor='phoneContact'>Allow phone contact</label>
                <input type="checkbox"
                    id="phoneContact"
                    name="contacts"
                    value="phone"
                    onChange={(e) => changeContact()}
                />
                <label htmlFor='mailContact'>Allow mail contact</label>
                <input type="checkbox"
                    id="mailContact"
                    name="contacts"
                    value="mail"
                    onChange={(e) => changeContact()}
                />
                <input type="submit" value="Submit" onClick={() => changeProfil("contact")} />
            </form>

        </div>
    )
}

export default AccountSettings