import Banner from "../components/Banner"
import PatientInfo from "../components/PatientInfo"


function PatientPage() {
  return (
    <body>
        <Banner/>
        <div><PatientInfo /></div>
    </body>
  )
}

export default PatientPage
