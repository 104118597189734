import React, { useState } from 'react'
import Banner from '../components/Banner'
import "../styles/meta.css"
import axios from 'axios';
import CustomSelect from '../components/CustomSelect';
import "../styles/select.css"
import environmentVariables from '../config/environmentVariables';

const handleSubmit = async () => {
  try {
    const TEST_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/test`;

    const response = await axios.post(TEST_URL,
      JSON.stringify({}),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    );
    console.log(response?.data)
  } catch (err) {

  }
}

function PrivateTestPage() {

  const option = ["A", "B", "C", "D", "E", 'F', "G", "H", "I"]

  const [result, setResult] = useState('')
  const [show, setShow] = useState(false)

  return (
    <body>
      <Banner />
      <div className='container'>
        <h1>Private Access</h1>
        <button onClick={handleSubmit}>Test</button>

        <CustomSelect className="cardSelect"
          placeholder="choississez une carte"
          options={option}
          result={ result}
          setResult={ setResult}
          isSearchable={ true }
          show={ show }
          setShow={ setShow } />
      </div>
    </body>
  )
}

export default PrivateTestPage
