import React, { useEffect, useState } from 'react'
import Patient from '../models/patientModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import axios from 'axios';

import "../styles/dashboard.css"
import { changePatient } from '../features/user';
import environmentVariables from '../config/environmentVariables';

function PatientDashboard() {
  const [patients, setPatients] = useState<Patient[]>([]);
  const user = useSelector((state: RootState) => state.user.userValue);
  const GETPATIENT_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/pro`;

  const dispatch = useDispatch();

  const [currentPatient, setCurrentPatient] = useState<Patient>();
  const [showPatient, setShowPatient] = useState<Boolean>(false);

  useEffect(() => {
    // Appelé une fois que le composant est monté
    fetchPatients();
  }, []); // Le tableau vide assure que cet effet ne s'exécute qu'une seule fois après le premier rendu

  /**
   * fetch patients followed by the logged professional and sort them
   */
  const fetchPatients = async () => {
    try {
      const response = await axios.post(GETPATIENT_URL,
        JSON.stringify({ user: user.user }),
        {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.accessToken}` },
          withCredentials: true
        }
      );
      const data = response?.data?.patients;
      data.sort((a: Patient, b: Patient) => a.name.localeCompare(b.name)); //sorting patient by name

      setPatients(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des patients", error);
    }
  };

  /**
   * @function handleSubmit
   * On click function to display a specific patient in a pop up window
   * @param {Patient} patient
   */
  function handleSubmit(patient: Patient) {
    setCurrentPatient(patient);
    setShowPatient(true);
    dispatch(changePatient(patient));
  }

  function close() {
    setShowPatient(false);
  }

  return (
    <div className='container container--row container--row--stretch'> {/* flex direction : row*/}
      <section className='patientList'> {/* vertical bar of patients*/}
        <ul className='patientList__element'>
          {patients?.map(patient => (
            <li key={patient.id} onClick={() => handleSubmit(patient)}>{patient.name} {patient.firstName}</li>
          ))}
        </ul>

      </section>

      <section className='patientBlock'> {/* grid display of patients*/}
        {patients?.map(patient => (
          <div key={patient.id} className={patient.contactRequest ? 'patientBlock__element patientBlock__element--request' : "patientBlock__element"} onClick={() => handleSubmit(patient)}>
            <img src={'/images/temp_img.jpeg'} />
            <span>{patient.name} {patient.firstName}</span>
          </div>
        ))}
        <a href='/create_patient'>
          <div> ADD A PATIENT </div>
        </a>

        <section className={showPatient ? 'patientPopUp' : "patientPopUp--hide"}> {/* pop up display of patient*/}
          <span onClick={() => close()} className='patientPopUp__close'>&times;</span>
          <div className={currentPatient?.contactRequest ? 'patientBlock__element patientBlock__element--request' : "patientBlock__element"} >
            <img src={'/images/temp_img.jpeg'} />
            <span>{currentPatient?.name} {currentPatient?.firstName}</span>
            {currentPatient && (
              <a href={`${environmentVariables.app.baseUrl}:${environmentVariables.app.frontPort}/patient/${currentPatient.id}/pfpp`} className='banner__list__element'>
                Accéder à la fiche Patient
              </a>
            )}
          </div>
        </section>

      </section>

    </div>
  )
}

export default PatientDashboard
