// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainBox__info--valid {
  display: flex;
  flex-direction: column;
  color: green;
}
.mainBox__info--invalid {
  display: flex;
  flex-direction: column;
  color: red;
}
.mainBox__info--instruction {
  display: flex;
  flex-direction: column;
}
.mainBox__info--offscreen {
  display: none;
}
.mainBox__info--carList {
  flex-direction: row;
}

`, "",{"version":3,"sources":["webpack://./src/styles/sass/register.scss","webpack://./src/styles/register.css"],"names":[],"mappings":"AAOI;EANA,aAAA;EACA,sBAAA;EAOI,YAAA;ACLR;ADQI;EAXA,aAAA;EACA,sBAAA;EAYI,UAAA;ACLR;ADQI;EAhBA,aAAA;EACA,sBAAA;ACWJ;ADQI;EACI,aAAA;ACNR;ADSI;EACI,mBAAA;ACPR","sourcesContent":["@mixin disflex {\n    display: flex;\n    flex-direction: column;\n}\n//TODO : change the modifier, currently wrong usage of BEM naming methode \n.mainBox__info {\n\n    &--valid {\n        @include disflex;\n        color: green;\n    }\n    \n    &--invalid {\n        @include disflex;\n        color:red;\n    }\n\n    &--instruction {\n        @include disflex()\n    }\n\n    &--offscreen {\n        display: none;\n    }\n\n    &--carList{\n        flex-direction: row;\n    }\n}\n\n",".mainBox__info--valid {\n  display: flex;\n  flex-direction: column;\n  color: green;\n}\n.mainBox__info--invalid {\n  display: flex;\n  flex-direction: column;\n  color: red;\n}\n.mainBox__info--instruction {\n  display: flex;\n  flex-direction: column;\n}\n.mainBox__info--offscreen {\n  display: none;\n}\n.mainBox__info--carList {\n  flex-direction: row;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
