/**
 * @module DisplayPFPPDetails
 * Display day by day of the details of the selected PFPP and modify it
 * TODO : select the PFPP
 * TODO : connect the changes to the back end
 * TODO : card selection does not save when changing day/week
 * @returns {any}
 */
import { useEffect, useState } from 'react'
import CustomSelect from './CustomSelect';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import environmentVariables from '../config/environmentVariables';
import { DailyCard, DailyData, DailyRehab, PFPP, PFPPWeek } from "../models/PFPPModel";
import { RootState } from '..';
import { useSelector } from 'react-redux';

function DisplayPFPPDetails() {

    //const GETCARDS_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/getCards`;
    const GETPFPP_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/getPFPPs`;

    const [searchParam] = useSearchParams();

    type RehabData = {
        title: string,
        position: string,
        poly: string,
        visu: string,
        rep: number,
        order: number
    }

    const [autoSave, setAutoSave] = useState(false)

    const tempRehabData: RehabData = {
        title: "titre",
        position: "pos",
        poly: "NA",
        visu: "ouvert",
        rep: 10,
        order: 0
    }
    const tempData: Array<RehabData> = [tempRehabData, tempRehabData, tempRehabData, tempRehabData, tempRehabData, tempRehabData, tempRehabData, tempRehabData, tempRehabData]

    const [rehabData, setRehabData] = useState<RehabData[]>(tempData)
    const [cardTitle, setCardTitle] = useState<string[]>([])
    const [showCardSelect, setShowCardSelect] = useState(false)
    const [choosenCard, setChoosenCard] = useState("")
    const [weekRep, setWeekRep] = useState("1")
    const [pfppRep, setPFPPRep] = useState("1")
    const [preSteps, setPreSteps] = useState(4000)
    const [dailySteps, setDailySteps] = useState(4500)
    const [postSteps, setPostSteps] = useState(5000)
    const user = useSelector((state: RootState) => state.user.userValue);

    const [pfpp, setPFPP] = useState<PFPP>()
    const [cards, setCards] = useState<any>()

    let tempDay = "1";
    let tempWeek = "1";
    let tempPFPP = "";

    if (searchParam.get("day") != null) {
        tempDay = (parseInt(searchParam.get("day")!, 10) + 1).toString()
    }

    if (searchParam?.get("week") != null) {
        tempWeek = (parseInt(searchParam.get("week")!, 10) + 1).toString()
    }

    if (searchParam?.get("pfpp") != null) {
        tempPFPP = searchParam?.get("pfpp")!
    }



    const [day, setDay] = useState(tempDay)
    const [week, setWeek] = useState(tempWeek)

    const [showDaySelect, setShowDaySelect] = useState(false)
    const [showWeekSelect, setShowWeekSelect] = useState(false)

    useEffect(() => {
        //fetchCards();
        fetchPFPP();
    }, []);

    useEffect(() => {
        try {
            if (pfpp) {
                try {
                    changeVisualData(pfpp);
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }, [day, week]);

    //TODO : fix this, should save the selected daily card if it was changed
    useEffect(() => {
        try {
            const card = cards.find((element : any) => element.title.localeCompare(choosenCard) == 0 )
            //pfpp.weeklyDatas[parseInt(week, 10) - 1].cards[parseInt(day, 10) - 1].card = card;
            //console.log(pfpp.weeklyDatas[parseInt(week, 10) - 1].cards[parseInt(day, 10) - 1].card)
            setPFPP(pfpp);
        } catch(error) {
            console.log(error)
        }
    }, [choosenCard])


    /*const fetchCards = async () => {
        try {
            const response = await axios.get(GETCARDS_URL,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const data = response?.data;
            if (data) {
                setCards(data)
                let titles : string[] = []
                data.map((e: any) => {
                    if (!(titles.includes(e.title))) {
                        titles = [...titles, e.title]
                    }
                })
                console.log(titles)
                setCardTitle(titles)
            }
        } catch (error) {
            console.log(error)
        }
    }*/

    const fetchPFPP = async () => {
        try {
            const response = await axios.get(GETPFPP_URL,
                {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.accessToken}` },
                    withCredentials: true
                }
            );
            const data = response?.data;
            if (data) {
                setPFPP(data[0])
                changeVisualData(data[0])
            }
        }
        catch (error) {
            console.log(error)
        }
    }
/**
 * @function ChangeVisualData
 * Change what data is displayed of the pfpp, called when day/week changes
 * @param {PFPP} data:PFPP
 * @returns {any}
 */

    const changeVisualData = (data: PFPP) => {
        let tempWeekRep = 0;
        let tempPFPPRep = 0;
        let tempCard = "";
        let numbWeek = parseInt(week, 10)
        let numbDay = parseInt(day, 10);

        data.pfppWeeks.map((element: PFPPWeek) => {
            //search for selected week
            if (element.week == numbWeek) {

                let tempRehab: RehabData[] = []

                //search for selected day card
                element.dailyCards.map((card: DailyCard) => {
                    if (card.day == numbDay) {
                        tempCard = card.title;
                        setChoosenCard(card.title)
                    }
                })

                //count how many times the card was inserted in the week
                element.dailyCards.map((card: DailyCard) => {
                    if (card.title.localeCompare(tempCard) == 0) {
                        tempWeekRep += 1;
                    }
                })

                //get all daily steps number displayed (J+1, J, J-1)
                element.dailyData.map((step: DailyData) => {

                    if (step.day == numbDay) {
                        setDailySteps(step.dailyStepsNumber)
                    }

                    if (step.day == numbDay - 1) {
                        setPreSteps(step.dailyStepsNumber)
                    }

                    if (step.day == numbDay + 1) {
                        setPostSteps(step.dailyStepsNumber)
                    }
                })

                //get daily rehab
                element.dailyRehabs.map((rehab: DailyRehab) => {
                    if (rehab.day == numbDay) {
                        tempRehab = [...tempRehab,
                        {
                            title: rehab.title,
                            position: rehab.rehabSeqTypes[0].rehabSeqs[0].position,
                            poly: "NA",
                            visu: rehab.rehabSeqTypes[0].rehabSeqs[0].visualInput,
                            rep: rehab.rehabSeqTypes[0].rehabSeqs[0].numberOrTime,
                            order: rehab.day
                        }
                        ]
                    }
                })
                console.log(tempRehab)

                tempRehab.sort((a, b) => {
                    return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);
                })
                setRehabData(tempRehab)
            }

        })
        //count how many times the card was inserted in the whole pfpp
        data.pfppWeeks.map((element: PFPPWeek) => {
            element.dailyCards.map((card: any) => {
                if (card.title.localeCompare(tempCard) == 0) {
                    tempPFPPRep += 1;
                }
            })
        })

        setWeekRep(tempWeekRep.toString())
        setPFPPRep(tempPFPPRep.toString())

        //get steps from last week if begining of the week
        if (numbDay == 0) {
            if (numbWeek == 0) {
                setPreSteps(0)
            } else {
                setPreSteps(data.pfppWeeks[numbWeek - 1].dailyData[6].dailyStepsNumber)
            }
        }

        //get steps from next week if end of the week
        if (numbDay == 6) {
            if (numbWeek == 6) {
                setPostSteps(0)
            } else {
                setPostSteps(data.pfppWeeks[numbWeek + 1].dailyData[0].dailyStepsNumber)
            }
        }

    }

    /**
     * @function changeDay
     * Change the day
     * @param {any} inc:number
     * @returns {any}
     */
    const changeDay = (inc: number) => {
        if (day == "1" && week != "1" && inc < 0) {
            setWeek((parseInt(week, 10) + inc).toString())
            setDay("7")
        } else if (day == "7" && week != "7" && inc > 0) {
            setWeek((parseInt(week, 10) + inc).toString())
            setDay("1")
        } else {
            if (!(day == "1" && inc < 0 || day == "7" && inc > 0)) {
                setDay((parseInt(day, 10) + inc).toString())
            }
        }
    }


    return (
        <>
            <div className='path'>
                <span className='displayPFPPText'>
                    Dossiers patients •  NOM Prénom •  Configuration du protocole
                </span>
            </div>

            <div className='displayPFPPMainBox'>
                <div className='displayPFPPMainBox__back'>
                    <div className='displayPFPPMainBox__back displayPFPPMainBox__back--pointer' onClick={e => history.back()}>
                        <label className='displayPFPPText'>&#60; </label>
                        <label className='displayPFPPText'>Retour</label>
                    </div>
                    {/*<div className='displayPFPPMainBox__back__autoSaveBox'>
                        <label className='displayPFPPText displayPFPPText--smaller'>Enregistrement automatique</label>
                        <label className='displayPFPPMainBox__switch'>
                            <input type="checkbox"
                                onChange={e => setAutoSave(e.target.checked)}
                            />
                            <span className='displayPFPPMainBox__switch__slider'></span>
                        </label>
                        <label className='displayPFPPText displayPFPPText--italic displayPFPPText--slim displayPFPPText--smaller'> {autoSave ? "activé" : "désactivé"}</label>
                    </div>*/}
                </div>

                <div className='displayPFPPMainBox__centerPanel'>
                    <div className='displayPFPPMainBox__arrowBox'>
                        <button
                            className='displayPFPPMainBox__arrowBox__btn'
                            onClick={e => changeDay(-1)}
                        >
                            <img src='/images/arrow_left.png' className='displayPFPPIcon' />
                            <img src='/images/arrow_left.png' className='displayPFPPIcon' />
                        </button>
                    </div>

                    <div className='displayPFPPMainBox__centerBox'>
                        <div className='displayPFPPMainBox__centerBox__selector'>
                            <div className='displayPFPPMainBox__centerBox__selector__item'>
                                <label className='displayPFPPText displayPFPPText--bold displayPFPPText--extra'>Semaine </label>
                                <CustomSelect className="numberSelect"
                                    placeholder="1"
                                    options={["1", "2", "3", "4", "5", "6", "7"]}
                                    result={week}
                                    setResult={setWeek}
                                    isSearchable={false}
                                    show={showWeekSelect}
                                    setShow={setShowWeekSelect} />
                            </div>

                            <div className='displayPFPPMainBox__centerBox__selector__item'>
                                <label className='displayPFPPText displayPFPPText--bold displayPFPPText--extra'>Jour </label>
                                <CustomSelect className="numberSelect"
                                    placeholder="1"
                                    options={["1", "2", "3", "4", "5", "6", "7"]}
                                    result={day}
                                    setResult={setDay}
                                    isSearchable={false}
                                    show={showDaySelect}
                                    setShow={setShowDaySelect} />
                            </div>
                        </div>

                        <div className='displayPFPPMainBox__centerPanel__rehab'>
                            <div className='displayPFPPMainBox__iconBox displayPFPPMainBox__iconBox--blue'>
                                <img src='/images/rehab_icon.png' className='displayPFPPImg' />
                            </div>

                            <div className='displayPFPPMainBox__rehabGrid'>
                                <div className='displayPFPPMainBox__rehabGrid__columnName displayPFPPMainBox__rehabGrid--noBorder'>S2 • [Titre de la séquence]</div>
                                <div className='displayPFPPMainBox__rehabGrid__columnName'>Position</div>
                                <div className='displayPFPPMainBox__rehabGrid__columnName'>Polygone sustention</div>
                                <div className='displayPFPPMainBox__rehabGrid__columnName'>Entrée visuelle</div>
                                <div className='displayPFPPMainBox__rehabGrid__columnName'>Nb répétitions / temps</div>

                                {rehabData.map(element => (
                                    <>
                                        <div className='displayPFPPMainBox__rehabGrid__title'> {element.title} </div>
                                        <div className='displayPFPPMainBox__rehabGrid__element'> {element.position} </div>
                                        <div className='displayPFPPMainBox__rehabGrid__element'> {element.poly} </div>
                                        <div className='displayPFPPMainBox__rehabGrid__element'> {element.visu} </div>
                                        <div className='displayPFPPMainBox__rehabGrid__element'> {element.rep} </div>
                                    </>
                                ))}
                            </div>
                        </div>

                        <div className='displayPFPPMainBox__item'>
                            <div className='displayPFPPMainBox__iconBox displayPFPPMainBox__iconBox--green'>
                                <img src='/images/card_icon.png' className='displayPFPPImg' />
                            </div>

                            <div className="cardSelect">
                                <label className={"cardSelect__mainText"}>  
                                    {choosenCard}
                                </label>
                            </div>

                            {/*<CustomSelect className="cardSelect"
                                placeholder="choississez une carte"
                                options={cardTitle}
                                result={choosenCard}
                                setResult={setChoosenCard}
                                isSearchable={true}
                                show={showCardSelect}
                                setShow={setShowCardSelect} />*/}

                            <div className='displayPFPPMainBox__item__countBox'>
                                <label className='displayPFPPText'>
                                    Utilisation de la fiche :
                                </label>

                                <p className='displayPFPPText displayPFPPText--slim displayPFPPText--uncenter'>
                                    <br />
                                    {pfppRep} / protocole <br />
                                    {weekRep} / semaine en cours <br />
                                </p>
                            </div>

                        </div>

                        <div className='displayPFPPMainBox__item'>
                            <div className='displayPFPPMainBox__iconBox displayPFPPMainBox__iconBox--purple'>
                                <img src='/images/step_icon.png' className='displayPFPPImg' />
                            </div>

                            <div className='displayPFPPMainBox__stepGrid'>
                                <div />
                                <div />
                                <div />
                                <label className='displayPFPPMainBox__stepGrid__number'>{preSteps}</label>
                                <div className='displayPFPPMainBox__stepGrid__mainBox'>
                                    <input
                                        className='displayPFPPMainBox__stepGrid__mainStep displayPFPPMainBox__stepGrid__number displayPFPPMainBox__stepGrid__number--bold'
                                        type='number'
                                        value={dailySteps.toString()}
                                        readOnly
                                        //onChange={e => setDailySteps(parseInt(e.target.value, 10))}
                                    />
                                    {/*<div className='displayPFPPMainBox__stepGrid__mainBox__arrow'>
                                        <div
                                            onClick={e => {
                                                const newDaily = dailySteps + 50;
                                                //setDailySteps(newDaily)
                                                //pfpp.weeklyDatas[parseInt(week, 10) - 1].dailys[parseInt(day, 10) - 1].dailyStepsNumber = newDaily
                                                setPFPP(pfpp)
                                            }}
                                        >
                                            <img src='/images/arrow_up.png' className='displayPFPPImg' />
                                        </div>
                                        <div
                                            onClick={e => {
                                                const newDaily = dailySteps - 50;
                                                //setDailySteps(newDaily)
                                                //pfpp.weeklyDatas[parseInt(week, 10) - 1].dailys[parseInt(day, 10) - 1].dailyStepsNumber = newDaily
                                                setPFPP(pfpp)
                                            }}
                                        >
                                            <img src='/images/arrow_down.png' className='displayPFPPImg' />
                                        </div>
                                        </div>*/}
                                </div>
                                <label className='displayPFPPMainBox__stepGrid__number'>{postSteps}</label>
                                <label className='displayPFPPText displayPFPPText--extra displayPFPPText--slim'>J-1</label>
                                <div />
                                <label className='displayPFPPText displayPFPPText--extra displayPFPPText--slim'>J+1</label>
                            </div>

                        </div>

                    </div>

                    <div className='displayPFPPMainBox__arrowBox'>
                        <button
                            className='displayPFPPMainBox__arrowBox__btn'
                            onClick={e => changeDay(1)}
                        >
                            <img src='/images/arrow_right.png' className='displayPFPPIcon' />
                            <img src='/images/arrow_right.png' className='displayPFPPIcon' />
                        </button>
                    </div>
                </div>

{/*
                <div className='displayPFPPMainBox__save'>
                    <button className='saveButton'>
                        <span className='displayPFPPText displayPFPPText--bold displayPFPPText--white'> Enregistrer </span>
                    </button>
                </div>
                                        */}
            </div>

        </>
    )
}

export default DisplayPFPPDetails
