import React from 'react'
import Banner from '../components/Banner'

export default function NoPage() {
  return (
    <body>
      <Banner/>
    </body>
  )
}
