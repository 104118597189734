import React from 'react'
import AccountSettings from '../components/AccountSettings'
import Banner from '../components/Banner'

function SettingPage() {
  return (
    <div>
      <Banner/>
      <div className='container'><AccountSettings /></div>
      
    </div>
  )
}

export default SettingPage
