import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import environmentVariables from '../config/environmentVariables';
import "../styles/displayPFPP.css";
import CustomSelect from './CustomSelect';
import { DailyCard, DailyData, DailyRehab, PFPP, PFPPWeek } from "../models/PFPPModel";
import { useSelector } from 'react-redux';
import { RootState } from '..';

const tempData = Array.from(Array(7).keys()).map(e => {
    return Array.from(Array(7).keys()).map(i => {
        return {
            card: "",
            rehab: "",
            step: ""
        };
    });
});

const tempProfil = "Protocole standard • Profil";

const tempProfils = Array.from(Array(4).keys()).map(e => {
    return tempProfil + e.toString();
});

// Fonction pour calculer l'âge à partir de la date de naissance
const calculateAge = (birthdate: Date) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Vérifier si l'anniversaire est passé cette année, sinon, soustraire un an de l'âge
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age.toString();
};

function DisplayPFPP() {
    const { id } = useParams<{ id: string }>(); // Récupérer l'ID du patient à partir des paramètres de l'URL

    const GETPFPP_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/getPFPPs`;
    const GETPATIENT_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/patients/${id}`;
    const CREATEPFPP_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/pfpp/create`;

    const user = useSelector((state: RootState) => state.user.userValue);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [age, setAge] = useState("");
    const [data, setData] = useState(tempData);
    const [selectablePFPP, setSelectablePFPP] = useState(tempProfils);
    const [value, setValue] = useState(tempProfils[0]);
    const [showSelect, setShowSelect] = useState(false);
    const [pfpp, setPFPP] = useState("");
    const [pfppDuration, setPFPPDuration] = useState(0);
    const [linkCode, setLinkCode] = useState(""); // Nouvel état pour le linkCode

    const [showRehab, setShowRehab] = useState(true);
    const [showCard, setShowCard] = useState(true);
    const [showStep, setShowStep] = useState(true);

    useEffect(() => {
        fetchPatient();
        fetchPFPP();
    }, [id]);

    const fetchPatient = async () => {
        try {
            const response = await axios.get(GETPATIENT_URL, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.accessToken}` },
                withCredentials: true
            });

            const patient = response.data;

            setFirstName(patient.firstName);
            setLastName(patient.name);
            setBirthdate(patient.birthdate);

            if (patient.birthdate) {
                const birthdate = new Date(patient.birthdate);
                const age = calculateAge(birthdate);
                setAge(age);
            } else {
                console.error('Birthdate is not available');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération du patient', error);
        }
    };

    const fetchPFPP = async () => {
        try {
            const response = await axios.get(GETPFPP_URL, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.accessToken}` },
                withCredentials: true
            });

            const data = response?.data;
            if (data && data.length > 0) {
                changeData(data[0]);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération du PFPP', error);
        }
    };

    const changeData = (pfppData: PFPP) => {
        setPFPP(pfppData.id);
        setPFPPDuration(pfppData.duration || 0)
        const weeklys = pfppData.pfppWeeks;
        weeklys.forEach((week: PFPPWeek) => {
            week.dailyCards.forEach((card: DailyCard) => {
                data[week.week - 1][card.day - 1].card = card.title;
            });
            week.dailyRehabs.forEach((rehab: DailyRehab) => {
                data[week.week - 1][rehab.day - 1].rehab = rehab.title;
            });
            week.dailyData.forEach((step: DailyData) => {
                data[week.week - 1][step.day - 1].step = (step.dailyStepsNumber / 1000).toString();
            });
        });
        setData([...data]);
    };

    const toggleData = (showData: boolean, setShowData: React.Dispatch<React.SetStateAction<boolean>>) => {
        setShowData(!showData);
    };

    const handleSave = async () => {

        try {
            const response = await axios.post(CREATEPFPP_URL, {
                patientId: id,
                patientBirthdate: birthdate,
                pfppId: pfpp,
                pfppDuration: pfppDuration
            }, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.accessToken}` },
                withCredentials: true
            });

            console.log('PFPP créé avec succès:', response.data);

            if (response.data.linkCode) {
                setLinkCode(response.data.linkCode); // Met à jour l'état du linkCode
            }
            // Vous pouvez ajouter du code pour gérer ce qui se passe après la création, comme afficher un message de succès ou rediriger l'utilisateur
        } catch (error) {
            console.error('Erreur lors de la création du PFPP', error);
        }
    };

    type Props = {
        i: number,
        j: number
    };

    const GridElement: React.FC<Props> = ({ i, j }) => {
        const rehab = data[i][j].rehab;
        const card = data[i][j].card;
        const step = data[i][j].step;

        let display: JSX.Element[] = [];

        if (showRehab && rehab) {
            display = [...display, <span className='displayPFPPText displayPFPPText--blue'>R : {rehab}</span>];
            if ((showCard && card) || (showStep && step)) {
                display = [...display, <span> | </span>];
            }
        }

        if (showCard && card) {
            display = [...display, <span className='displayPFPPText displayPFPPText--green'>H : {card}</span>];
            if (showStep && step) {
                display = [...display, <span> | </span>];
            }
        }

        if (showStep && step) {
            display = [...display, <span className='displayPFPPText displayPFPPText--purple'>M : {step}</span>];
        }

        return (
            <div>
                {display.map((e, index) => (
                    <React.Fragment key={index}>{e}</React.Fragment>
                ))}
            </div>
        );
    };

    return (
        <>
            <div className='path'>
                <span className='displayPFPPText'>
                    Dossiers patients • {firstName} {lastName} • Configuration du protocole
                </span>
            </div>
            <div className='displayPFPPMainBox'>
                <div className='displayPFPPMainBox__back'>
                    <div className='displayPFPPMainBox__back'>
                        <label className='displayPFPPText'>&#60; </label>
                        <label className='displayPFPPText'>Retour</label>
                    </div>
                </div>

                <div className='secondGrid'>
                    <div className='secondGrid__infoElement'>
                        <label className='displayPFPPText displayPFPPText--bold displayPFPPText--extra'>
                            PROTOCOLE PERSONNALISE DE REEDUCATION
                        </label>
                        <div className='displayPFPPText'>
                            <label className='displayPFPPText displayPFPPText--bold'>Patient:</label>
                            <label className='displayPFPPText'>{firstName}</label>
                            <label className='displayPFPPText'>{lastName}</label>
                        </div>
                        <div className='displayPFPPText'>
                            <label className='displayPFPPText displayPFPPText--bold'>Age:</label>
                            <label className='displayPFPPText'>{age}</label>
                        </div>
                    </div>
                    <div className='secondGrid__pfppElement'>
                        <div className='secondGrid__pfppElement secondGrid__pfppElement--center'>
                            <label className='displayPFPPText displayPFPPText--bold'>Protocole préféfini:</label>
                            <CustomSelect
                                className="pfppSelect"
                                placeholder="choississez un pfpp"
                                options={selectablePFPP}
                                result={value}
                                setResult={setValue}
                                isSearchable={false}
                                show={showSelect}
                                setShow={setShowSelect}
                            />
                        </div>
                    </div>
                </div>

                <div className='displayPFPPMainBox__back'>
                    <button className='displayPFPPMainBox__button displayPFPPMainBox__button--nonDisplay'></button>

                    <button
                        className={showRehab ? 'displayPFPPMainBox__button displayPFPPMainBox__button--blue' : 'displayPFPPMainBox__button displayPFPPMainBox__button--lightBlue'}
                        onClick={() => toggleData(showRehab, setShowRehab)}
                    >
                        <img src='/images/rehab_icon.png' className='displayPFPPIcon' />
                        <div className={showRehab ? 'displayPFPPText displayPFPPText--bold displayPFPPText--white' : 'displayPFPPText displayPFPPText--bold displayPFPPText--blue'}>
                            Rééduation
                        </div>
                    </button>
                    <button
                        className={showCard ? 'displayPFPPMainBox__button displayPFPPMainBox__button--green' : 'displayPFPPMainBox__button displayPFPPMainBox__button--lightGreen'}
                        onClick={() => toggleData(showCard, setShowCard)}
                    >
                        <img src='/images/card_icon.png' className='displayPFPPIcon' />
                        <div className={showCard ? 'displayPFPPText displayPFPPText--bold displayPFPPText--white' : 'displayPFPPText displayPFPPText--bold displayPFPPText--green'}>
                            Hygiène de vie
                        </div>
                    </button>
                    <button
                        className={showStep ? 'displayPFPPMainBox__button displayPFPPMainBox__button--purple' : 'displayPFPPMainBox__button displayPFPPMainBox__button--lightPurple'}
                        onClick={() => toggleData(showStep, setShowStep)}
                    >
                        <img src='/images/step_icon.png' className='displayPFPPIcon' />
                        <div className={showStep ? 'displayPFPPText displayPFPPText--bold displayPFPPText--white' : 'displayPFPPText displayPFPPText--bold displayPFPPText--purple'}>
                            Marche
                        </div>
                    </button>
                </div>

                <div className='mainGrid'>
                    <div className='mainGrid__week mainGrid--noBorder'></div>
                    {Array.from(Array(7).keys()).map(e => (
                        <span key={e} className='mainGrid__day'>Jour {e + 1}</span>
                    ))}
                    {Array.from(Array(7).keys()).map(i => (
                        <React.Fragment key={i}>
                            <span className='mainGrid__week'>Semaine {i + 1}</span>
                            {Array.from(Array(7).keys()).map(j => (
                                <a key={j} href={`/patient/pfpp/details?pfpp=${pfpp}&day=${j}&week=${i}`} className='mainGrid__element'>
                                    <GridElement i={i} j={j} />
                                </a>
                            ))}
                        </React.Fragment>
                    ))}
                    <div className='mainGrid__week mainGrid--end'></div>
                    {Array.from(Array(7).keys()).map(e => (
                        <div key={e} className='mainGrid__element mainGrid--end'></div>
                    ))}
                </div>
                {linkCode && ( // Afficher le linkCode s'il est disponible
                        <div className='linkCode'>
                            <span className='displayPFPPText displayPFPPText--bold'>link code : {linkCode}</span>
                        </div>
                )}
                <div className='displayPFPPMainBox__save'>
                    <button className='saveButton' onClick={handleSave}>
                        <span className='displayPFPPText displayPFPPText--bold displayPFPPText--white'>Enregistrer</span>
                    </button>
                </div>
            </div>
        </>
    );
}

export default DisplayPFPP;
