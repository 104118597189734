import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { login } from '../features/user';
import "../styles/login.css";
import environmentVariables from '../config/environmentVariables';

const LOGIN_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/login`;
const VERIFY_CODE_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/verifyCode`;
const REGISTERPAGE_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.frontPort}/register`;
const FORGOTPAGE_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.frontPort}/forgot`;

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const dispatch = useDispatch();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [verifCode, setVerifCode] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [maskedPhone, setMaskedPhone] = useState('');

    const [validName, setValidName] = useState(false);
    const [validPwd, setValidPwd] = useState(false);
    const [validVerifCode, setValidVerifCode] = useState(false);
    const [phoneVerificationRequired, setPhoneVerificationRequired] = useState(false);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, verifCode]);

    useEffect(() => {
        setValidName(user.length > 3);
    }, [user]);

    useEffect(() => {
        setValidPwd(pwd.length > 3);
    }, [pwd]);

    useEffect(() => {
        setValidVerifCode(verifCode.length === 6);
    }, [verifCode]);

    const handleInitialSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user: user, password: pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            if (response.data.phone) {
                setPhoneVerificationRequired(true);
                setMaskedPhone(response.data.phone);
                setVerifCode(response.data.code);
            } else if (response.data.accessToken) {
                const accessToken = response?.data?.accessToken;
                dispatch(login({ user: user, accessToken: accessToken }));

                setUser('');
                setPwd('');
                setVerifCode('');
                navigate(from, { replace: true });
            }
        } catch (err) {
            setErrMsg('Les informations saisies ne semblent pas valides. Veuillez corriger votre saisie. Pour toute question, contactez le service d’assistance de Mementop•Gait Pro');
        }
    }

    const handleCodeVerificationSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        try {
            const response = await axios.post(VERIFY_CODE_URL,
                JSON.stringify({ user: user, code: verifCode }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            const accessToken = response?.data?.accessToken;
            dispatch(login({ user: user, accessToken: accessToken }));

            setUser('');
            setPwd('');
            setVerifCode('');
            navigate(from, { replace: true });
        } catch (err) {
            setErrMsg('Le code de vérification est invalide. Veuillez réessayer.');
        }
    }

    return (
        <section className='mainBox'>
            <form onSubmit={phoneVerificationRequired ? handleCodeVerificationSubmit : handleInitialSubmit}>
                <h1 className='mainBox__title'>Bienvenue sur la plate-forme Mementop•Gait Pro</h1>
                <label htmlFor="username"></label>
                <div className='mainBox__inputBox'>
                    <input
                        type="text"
                        id="username"
                        autoComplete="off"
                        placeholder='Username'
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        required
                        disabled={phoneVerificationRequired}
                    />
                    <img src='/images/profil_icon.svg' className='mainBox__inputBox__icon' />
                </div>

                <label htmlFor="password"></label>
                <div className='mainBox__inputBox'>
                    <input
                        type="password"
                        id="password"
                        placeholder='Mot de passe'
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                        disabled={phoneVerificationRequired}
                    />
                    <img src='/images/pwd_icon.svg' className='mainBox__inputBox__icon' />
                    <img src='/images/view_icon.svg' className='mainBox__inputBox__icon mainBox__inputBox__icon--right' />
                </div>

                {phoneVerificationRequired && (
                    <div>
                        <p>Un code de vérification a été envoyé au numéro suivant : {maskedPhone}</p>
                        <label htmlFor="verifCode"></label>
                        <div className='mainBox__inputBox'>
                            <input
                                type="text"
                                id="verifCode"
                                placeholder='Code de vérification'
                                onChange={(e) => setVerifCode(e.target.value)}
                                value={verifCode}
                                required
                            />
                            <img src='/images/phone_icon.svg' className='mainBox__inputBox__icon' />
                        </div>
                    </div>
                )}

                <div className='mainBox__remember-forgot'>
                    <label><input type='checkbox' /> Rester connecté </label>
                    <a href={FORGOTPAGE_URL}> Mot de passe oublié? </a>
                </div>

                <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                <button type="submit" className={!validName || !validPwd || (phoneVerificationRequired && !validVerifCode) ? 'mainBox__btn mainBox__btn--disable' : "mainBox__btn"} disabled={!validName || !validPwd || (phoneVerificationRequired && !validVerifCode) ? true : false}>SE CONNECTER</button>
            </form>

            <div className="mainBox__register">
                Besoin d'un compte?<br /> <a href={REGISTERPAGE_URL}>S'enregistrer</a>
            </div>
        </section>
    );
}

export default Login;
