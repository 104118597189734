/**
 * Redux slice of user, define the reducers of userSlice
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Patient from "../models/patientModel"
import Address from "../models/addressModel";

const initialAdress : Address = {
    streetNumber: "",
    streetName: "",
    zipCode: "",
    city: "",
    country: ""
}

const patientValue : Patient = {
    id: "",
    firstName: "",
    name: "",
    birthdate: "",
    nir: "",
    address: initialAdress,
    phone: "",
    email: "",
    sex: "",
    notes: "",
    contactRequest: false,
}

export const userSlice = createSlice({
    name: "user",
    initialState: {
        userValue: {
            user: "",
            accessToken: ""
        },
        patientValue
    }, //initial data of user and patient, empty

    reducers: {
        //simple reducer, change the current data with the new data
        login: (state, action) => {
            state.userValue = action.payload;
        },
        changePatient: (state, action : PayloadAction<Patient>) => {
            state.patientValue = action.payload;
        }
    },
});

export const { login, changePatient } = userSlice.actions

export default userSlice.reducer;
