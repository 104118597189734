import React from 'react'
import Banner from '../components/Banner'
import "../styles/role.css"

export default function Home() {
  return (
    <div>
      <Banner />
      <div className='container'>
        <h1> Accueil </h1>
        <a href='/register'>
          <div>
            Register
          </div>
        </a>
        <a href='/login'>
          Login
        </a>
      </div>
    </div>
  )
}
