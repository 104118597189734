
const environmentVariables = {
    app: {
      secretKey: process.env.SECRET_KEY,
      baseUrl: process.env.BASE_URL || 'https://pro.standhop.com',
      backPort: process.env.BACK_PORT || '8444',
      frontPort: process.env.FRONT_PORT || '443'
    },
};
  
export default environmentVariables;
