import React from 'react'
import Banner from '../components/Banner'
import ResetPassword from '../components/ResetPassword'

function ResetPage() {
  return (
    <body>
        <Banner/>
        <div className='container'><ResetPassword/></div>
    </body>
  )
}

export default ResetPage
