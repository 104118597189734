import React from 'react'
import Banner from '../components/Banner'
import ForgotPassword from '../components/ForgotPassword'

function ForgotPage() {
  return (
    <body>
        <Banner/>
        <div className='container'><ForgotPassword/></div>
    </body>
  )
}

export default ForgotPage
