import React from 'react'
import Banner from '../components/Banner'
import PatientDashboard from '../components/PatientDashboard'

import "../styles/dashboard.css"
import "../styles/meta.css"

function DashBoard() {
  return (
    <body>
      <div className='container container--uncenter'>
        <Banner />
        <PatientDashboard />
      </div>
    </body>
  )
}

export default DashBoard
