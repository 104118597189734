import React from 'react'
import PatientForm from '../components/PatientForm'
import Banner from '../components/Banner';
import "../styles/meta.css"

function PatientFormPage() {
  return (
    <body>
      <Banner/>
      <div className='container'><PatientForm /></div>
    </body>
  )
}

export default PatientFormPage;
