/**
 * @module CustomSelect
 * Customisable dropdown box and menu (see select.scss for more information on how to custom it)
 * @param {any} className name of the base css used
 * @param {any} placeholder displayed element as placeholder
 * @param {any} options element shown in dropdown menu
 * @param {any} result element returned when selected (state)
 * @param {any} setResult setState of the result state
 * @param {any} isSearchable boolean to able searchable
 * @param {any} show display state of the dropdown menu
 * @param {any} setShow setState of display
 * @returns {any}
 */
import React, { useEffect, useState } from 'react'

const CustomSelect = ({ className, placeholder, options, result, setResult, isSearchable, show, setShow }: { className: string, placeholder: string, options: string[], result: any, setResult: any, isSearchable: boolean, show: any, setShow: any }) => {

    const toggle = () => {
        setShow(!show)
    }
    
    const [shownOptions, setShownOptions] = useState(options)
    const [search, setSearch] = useState("")
    
    useEffect(() => {
        setShownOptions(options)
    },[options]); 



    return (
        <div className={className}>
            <div className={className + "__firstBox"} onClick={e => toggle()}>
                {result ?
                    <label className={className + "__mainText"}> {result} </label>
                    :
                    <label className={className + "__mainText"}> {placeholder} </label>
                }
                <img src='' />
            </div>

            {isSearchable ?
                <>
                    <div className={show ? className + "__selectBox" : className + "--hide"}>
                        <input
                            type='text'
                            value={search}
                            placeholder='Votre Recherche'
                            className={className + "__selectBox__input"}
                            onChange={e => {
                                setSearch(e.target.value)
                                if (e.target.value != "") {
                                    setShownOptions(options.filter(i => i.includes(e.target.value)))
                                } else {
                                    setShownOptions(options)
                                }
                            }}
                        />
                        {shownOptions.map(element => (
                            <div
                                key={element}
                                className={className + "__selectBox__item"}
                                onClick={e => {
                                    setResult(element);
                                    toggle()
                                }}>
                                <label className={className + "__selectBox__item__text"}> {element} </label>
                            </div>
                        ))}
                    </div>
                </>
                :
                <>
                    <div className={show ? className + "__selectBox" : className + "--hide"}>

                        {options.map(element => (
                            <div
                                key={element}
                                className={className + "__selectBox__item"}
                                onClick={e => {
                                    setResult(element);
                                    toggle()
                                }}>
                                <label className={className + "__selectBox__item__text"}> {element} </label>
                            </div>
                        ))}
                    </div>
                </>
            }

        </div>
    )
}

export default CustomSelect
