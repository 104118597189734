/** @module Role
 * Role main component, return a html div that allows to change user roles in the backend database
 * Will probably be dev only
 * @returns {any}
 */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../..'
import axios from 'axios'
import { Auth } from '../RequireAuth'
import { ButtonGroup } from '@mui/material'
import { login } from '../../features/user'
import environmentVariables from '../../config/environmentVariables';

const ADDROLE_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}//users/addRole`
const CHANGEROLE_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}//users/changeRole`

function Role() {

    const [userState, setUserState] = useState("");
    const user = useSelector((state: RootState) => state.user.userValue);
    const [err, setErr] = useState("no issue");
    const dispatch = useDispatch();

    const addRoleSubmit = async (role: number) => {
        //TODO : don't forget to delete this
        if (/*Auth([role],user)*/ true) {
            try {
                const response = await axios.post(ADDROLE_URL,
                    JSON.stringify({ userState, role }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                );
                console.log(response?.data?.error);
                setUserState("");
                setErr(response?.data?.error || "no issue");
                if (!(response?.data?.error)) {
                    const accessToken = response?.data?.accessToken;
                    //dispatch to redux session storage
                    dispatch(login({ user: user.user, accessToken: accessToken }));
                }





            } catch (error) {
                console.log("something weird happened")
            }
        } else {
            setErr("You do not have the permission for this")
        }

    }

    const changeRoleSubmit = async (role: number) => {

        if (Auth([role], user)) {
            try {
                const response = await axios.post(CHANGEROLE_URL,
                    JSON.stringify({ userState, role }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                );
                setUserState("");
                setErr("no issue");
                const accessToken = response?.data?.accessToken;


                //dispatch to redux session storage
                dispatch(login({ user: user.user, accessToken: accessToken }));

            } catch (error) {
            }
        } else {
            setErr("You do not have the permission for this");
        }
    }

    return (
        <div>
            <label htmlFor="username"></label>
            <div className='box'>
                <input
                    type="text"
                    id="username"
                    autoComplete="off"
                    placeholder='Username'
                    onChange={(e) => setUserState(e.target.value)}
                    value={userState}
                    required
                />
            </div>
            <ButtonGroup>
                <button onClick={(e: any) => addRoleSubmit(2001)}>Add User role</button>
                <button onClick={(e: any) => addRoleSubmit(5150)}>Add Admin Role</button>
                <button onClick={(e: any) => changeRoleSubmit(2001)}>Change role to User</button>
                <button onClick={(e: any) => changeRoleSubmit(5150)}>Change role to Admin</button>
            </ButtonGroup>

            <text>{err}</text>


        </div>
    )
}

export default Role
