import App from './App';
import { createRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import userReducer from "./features/user";
import storage from 'redux-persist/es/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from "redux-thunk";
import { PersistGate } from 'redux-persist/integration/react';

const persisteConfig = {
    key: "root",
    storage,
}
//change reducer to persistent reducer
const persistedReducer = persistReducer(persisteConfig, userReducer)

//create redux store to save data
const store = configureStore({
    reducer: {
        user: persistedReducer,
    },
    middleware: [thunk]
});

//make the store persist during the session
const persistor = persistStore(store);

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>

    );
} else {
    console.error('Failed to find the root element');
}

//export types to use the store in child components
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;