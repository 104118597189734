/**
 * @module PatientInfo
 * Display the selected patient information in details and allow the modification of its data
 * @returns {any}
 */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import Address from '../models/addressModel';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { changePatient } from '../features/user';
import '../styles/patient.css'
import { PFPP } from "../models/PFPPModel";
import environmentVariables from '../config/environmentVariables';

function PatientInfo() {

  const patient = useSelector((state: RootState) => state.user.patientValue);
  const GETPFPP_URL = ""
  const [pfpp, setPfpp] = useState<PFPP>();
  const [showModify, setShowModify] = useState<Boolean>(false);
  const [showCreate, setShowCreate] = useState<Boolean>(false);

  const dispatch = useDispatch();
  const PATIENTCHANGES_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/changePatientValues`;
  const CREATE_PFPP_URL = "/pfpp"
  const CREATE_PFPPMODEL_URL = "/modelpfpp"

  const name = useState<string>(patient.name);
  const firstName = useState<string>(patient.firstName);
  const nir = useState<string>(patient.nir);
  const phone = useState<string>(patient.phone);
  const email = useState<string>(patient.email);
  const birthdate = useState<string>(patient.birthdate);
  const sex = useState<string>(patient.sex);
  const notes = useState<string>(patient.notes);
  const contactRequest = useState<boolean>(patient.contactRequest);

  const [streetNumber, setStreetNumber] = useState<string>(patient.address.streetNumber)
  const [streetName, setStreetName] = useState<string>(patient.address.streetName)
  const [zipCode, setZipCode] = useState<string>(patient.address.zipCode)
  const [city, setCity] = useState<string>(patient.address.city)
  const [country, setCountry] = useState<string>(patient.address.country)

  const settings = [   //types need to be writed the SAME as patientModel attribute

    {
      "type": 'name',
      "state": name
    },
    {
      "type": 'firstName',
      "state": firstName
    },
    {
      "type": 'sex',
      "state": sex
    },
    {
      "type": 'email',
      "state": email
    },
    {
      "type": 'phone',
      "state": phone
    },
    {
      "type": 'nir',
      "state": nir
    },
    {
      "type": 'notes',
      "state": notes
    },



  ]
  useEffect(() => {
    fetchPFPP();
  }, []); 


  const fetchPFPP = async () => {
    try {
      const response = await axios.get(GETPFPP_URL,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
      );
      const data = response?.data?.PFPP;
      setPfpp(data);
    } catch (error) {

    }
  }




  function AddressDisplay(address: Address) {
    return (
      <div>
        <span>Addresse : {address?.streetNumber} {address?.streetName},</span>
        <span>{address?.zipCode} {address?.city}, {address?.country}</span>
      </div>
    )
  }



  function PFPPDisplay() {
    return (
      <>
        {pfpp ? (
          <>
            <button> PFPP Details </button>
            <button> Modify PFPP </button>
            <button> Conclude PFPP </button>
          </>
        ) : (
          <>
            <button onClick={(e) => setShowCreate(true)}> Create PFPP </button>
          </>
        )}
      </>)
  }

  const changeProfil = async (type: string) => {
    //depending on which type of info has been updated, we get the according state to send it to the backend
    let currentState: string | Boolean | Address = ""
    const setting = settings.filter(
      function (data) { return data.type == type });

    switch (type) {
      case 'address':
        currentState = {
          streetNumber: streetNumber,
          streetName: streetName,
          zipCode: zipCode,
          city: city,
          country: country
        }
        break;
      case 'birthdate':
        currentState = birthdate[0];
        break;
      case 'contactRequest':
        let temp = (document.getElementById('requestContact') as HTMLInputElement)?.checked
        contactRequest[1](temp)
        currentState = temp;
        break;
      default:
        currentState = setting[0].state[0];
        break;
    }

    try {
      const response = await axios.post(PATIENTCHANGES_URL,
        JSON.stringify({ id: patient.id, type: type, state: currentState }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      console.log("Settings changed successfully")
      const newPatient = response?.data?.patient;
      dispatch(changePatient(newPatient));

    }
    catch (error) {
      console.error("Erreur", error);
    }
  }


  return (
    <div className='patientContainer'>
      <div className='patientMainBox'>
        <p className='patientMainBox__mainTitle'> Informations sur le Patient </p>
        <div className='patientMainBox--uncenteredElement'>
          <span>Nom : {patient?.name}          Prénom : {patient?.firstName}</span>
          <span>Date de naissance : {patient?.birthdate}</span>
          <span>Sexe : {patient?.sex}</span>
          {AddressDisplay(patient?.address)}
          <span>Email : {patient?.email}</span>
          <span>Téléphone : {patient?.phone}</span>
          <span>NIR : {patient?.nir}</span>
          <span>Notes : {patient?.notes}</span>
        </div>
        {PFPPDisplay()}
        <button onClick={() => setShowModify(true)}>Modify Patient Info</button>
      </div>

      <section className={showCreate ? 'patientPopUp' : "patientPopUp--hide"}>
        <span onClick={() => setShowCreate(false)} className='patientPopUp__close'>&times;</span>
        <div className='createPFPPDisplayMainBox'>
        <form action={CREATE_PFPPMODEL_URL}>
            <input type='submit' value="Create PFPP from model" />
          </form>
          <form action={CREATE_PFPP_URL}>
            <input type='submit' value="Create PFPP from scratch" />
          </form>
        </div>
        <div className='ghostDivClose' onClick={(e) => setShowCreate(false)}></div>
      </section>

      <section className={showModify ? 'patientPopUp' : "patientPopUp--hide"}> {/* pop up display to modify patient's info*/}
        <span onClick={() => setShowModify(false)} className='patientPopUp__close'>&times;</span>
        <div className='patientModifyBox'>
          {settings.map(setting => (
            <form key={setting.type} onSubmit={e => e.preventDefault()}>
              <label htmlFor={setting.type}>New {setting.type}</label>
              <div className='patientModifyBox__inputBox'>
                <input
                  type="text"
                  id={setting.type}
                  autoComplete="off"
                  placeholder={setting.type}
                  onChange={(e) => setting.state[1](e.target.value)}
                  value={setting.state[0]}
                  required
                />
              </div>
              <input type="submit" value="Submit" className='patientModifyBox__btn' onClick={() => changeProfil(setting.type)}></input>
            </form>
          ))}

          <form key="address" onSubmit={e => e.preventDefault()}>
            <label htmlFor="address">New Address</label>
            <div className='patientModifyBox__inputBox'>
              <input
                type="text"
                id="number"
                autoComplete="off"
                placeholder="Street Number"
                onChange={(e) => setStreetNumber(e.target.value)}
                value={streetNumber}
                required
              />
            </div>

            <div className='patientModifyBox__inputBox'>
              <input
                type="text"
                id="name"
                autoComplete="off"
                placeholder="Street Name"
                onChange={(e) => setStreetName(e.target.value)}
                value={streetName}
                required
              />
            </div>

            <div className='patientModifyBox__inputBox'>
              <input
                type="text"
                id="zip"
                autoComplete="off"
                placeholder="Zip Code"
                onChange={(e) => setZipCode(e.target.value)}
                value={zipCode}
                required
              />
            </div>

            <div className='patientModifyBox__inputBox'>
              <input
                type="text"
                id="city"
                autoComplete="off"
                placeholder="City"
                onChange={(e) => setCity(e.target.value)}
                value={city}
                required
              />
            </div>

            <div className='patientModifyBox__inputBox'>
              <input
                type="text"
                id="country"
                autoComplete="off"
                placeholder="Country"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
                required
              />
            </div>
            <input type="submit" value="Submit" className='patientModifyBox__btn' onClick={() => changeProfil("address")}></input>
          </form>

          <form key="birthdate" onSubmit={e => e.preventDefault()}>
            <label htmlFor="Birthdate">New birthdate</label>
            <div className='patientModifyBox__inputBox'>
              <input
                type="date"
                id="birthdate"
                autoComplete="off"
                placeholder={birthdate[0]}
                onChange={(e) => birthdate[1](e.target.value)}
                value={birthdate[0]}
                required
              />
            </div>
            <input type="submit" value="Submit" className='patientModifyBox__btn' onClick={() => changeProfil("birthdate")}></input>
          </form>

          <label htmlFor='request'>Request Contact</label>
          <input type="checkbox"
            id="requestContact"
            name="contacts"
            value="request"
            onChange={(e) => changeProfil("contactRequest")}
            checked={contactRequest[0]}
          />
        </div>
        <div className='ghostDivClose' onClick={(e) => setShowModify(false)}></div>
      </section>
    </div>
  )
}

export default PatientInfo
