/**
 * @module PatientForm
 * Display a form to add patient to the current logged professional
 * @returns {any}
 */
import React, { useState, useEffect } from 'react';
import Patient from '../models/patientModel';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import axios from 'axios';
import environmentVariables from '../config/environmentVariables';

const PatientForm: React.FC = () => {

  const user = useSelector((state: RootState) => state.user.userValue);

  const firstNameState = useState('');
  const surNameState = useState('');
  const birthdateState = useState('');
  const [birthdateError, setBirthdateError] = useState('');
  const [patients, setPatients] = useState<Patient[]>([]);
  const states = [firstNameState, surNameState, birthdateState];


  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const response = await fetch(`${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/patients`, {
        headers: {
          'Authorization': `Bearer ${user.accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut: ${response.status}`);
      }
      const data = await response.json();
      setPatients(data); // Mettre à jour l'état avec les utilisateurs récupérés
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs", error);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior
    
    let valid = true;
    
    // Validate birthdate
    if (!birthdateState[0]) {
      setBirthdateError('La date de naissance est obligatoire.');
      valid = false;
    } else {
      setBirthdateError('');
    }
    
    // If the form is not valid, return early
    if (!valid) {
      return;
    }

    console.log('Tentative d\'envoi des données de l\'utilisateur...');
    try {
      const response = await fetch(`${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({ firstName: firstNameState[0], name: surNameState[0], birthdate: birthdateState[0], user: user.user })
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut: ${response.status}`);
      }

      const data = await response.json();
      console.log('Utilisateur créé avec succès:', data);

      // Réinitialiser l'input après envoi réussi
      states.forEach(state => state[1](""));

      // Mise à jour de la liste des utilisateurs après ajout
      fetchPatients();
    } catch (error) {
      console.error("Erreur lors de l'envoi du nom", error);
    }
  };

  function createField(fieldName: string, type: string, state: [string, React.Dispatch<React.SetStateAction<string>>]) {
    return (
      <label>
        {fieldName}:
        <input type={type} value={state[0]} onChange={(e) => state[1](e.target.value)} />
      </label>
    );
  };

  const handleTest = async () => {
    try {
      const response = await axios.post(`${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/test`,
        JSON.stringify({}),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
    } catch (error) {
      console.log("usk2");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Nom:
          <input type="text" value={surNameState[0]} onChange={(e) => surNameState[1](e.target.value)} />
        </label>
        {createField("Prénom", "text", firstNameState)}
        <label>
          Date de naissance:
          <input type="date" value={birthdateState[0]} onChange={(e) => birthdateState[1](e.target.value)} />
        </label>
        {birthdateError && <p style={{ color: 'red' }}>{birthdateError}</p>}
        <button type="submit">Submit</button>
      </form>

      {/* Patient List */}
      <h2>Liste des Patients</h2>
      <ul>
        {patients.map(patient => (
          <li key={patient.id}>{patient.name} {patient.firstName} {patient.birthdate}</li>
        ))}
      </ul>
      <button onClick={() => handleTest()} color='blue'>Test..</button>
    </div>
  );
};

export default PatientForm;
