import React from 'react'
import Register from '../components/Register'
import Banner from '../components/Banner'
import "../styles/meta.css"

export default function RegisterPage() {
  return (
    <body>
      <Banner/>
      <div className='container'>
        <Register />
      </div>
    </body>
  )
}
