import React from 'react'
import CsvUpload from '../components/exemples/CsvUpload'
import Banner from '../components/Banner'

function FilePage() {
  return (
    <body>
        <Banner/>
        <div className='container'><CsvUpload/></div>
    </body>
  )
}

export default FilePage
