import React from 'react'
import Banner from '../components/Banner'
import DisplayPFPP from '../components/DisplayPFPP'

function DisplayPFPPPage() {
  return (
    <body>
        <Banner />
        <div className='displayContainer'> <DisplayPFPP/> </div>

    </body>
  )
}

export default DisplayPFPPPage
