import React from 'react'
import Login from '../components/Login'
import Banner from '../components/Banner'
import "../styles/meta.css"

export default function LoginPage() {
  return (
    <body>
      <Banner />
      <div className='container container--background'>
        <div className='container container--coloredBackground'>
          <Login />
        </div>
      </div>

    </body>
  )
}
