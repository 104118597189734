/**
 * @module Index
 * Return a clickable list of all urls listed in url.json
 * @param {json} 'url'
 */
import { useLocation } from "react-router-dom";
import url from "../data/url.json"
import '../styles/navigation.css'
function Index() {
  const location = useLocation();
  return (
    <>
          {url.map(url => (<li key={url.id} className="banner__list">
            <a href={url.url} className={location.pathname.includes(url.url) ? "banner__list__element banner__list__element--active" :"banner__list__element"}>{url.name}</a>
            </li>))}
    </>
  )
}

export default Index