/**
 * @module ResetPassword
 * ResetPassword main component, display a form to reset user's password
 * @returns {any}
 */
import { useState, useEffect } from 'react';
import axios from "axios"
import { login } from '../features/user';
import "../styles/login.css"
import { useSearchParams } from 'react-router-dom';
import environmentVariables from '../config/environmentVariables';

const VERIFY_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/verifyReset`;
const RESET_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/resetPassword`;

/**
 * @function ResetPassword
 * Main function of the module
 * @returns 
 */
const ResetPassword = () => {

    const [pwd, setPwd] = useState("");
    const [email, setEmail] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [searhParam] = useSearchParams();
    const [tokenVerified, setTokenVerified] = useState(false);
    const [success, setSuccess] = useState(false); //TODO : once the password was reset successfully, display a message

    useEffect( () => { //useEffect used to verify the token passed as an argument in the url of the page
        const verify = async () => {const token = searhParam.get("resetToken"); //get the token passed in the page's url
        if (token) {
            setTokenVerified(await verifyToken(token))
        } else {
            setTokenVerified(false);
        }}
        verify();
    }, [searhParam])

    useEffect(() => {
        setErrMsg('');
    }, [pwd]);




    /**
     * @function verifyToken
     * send a request to the back end to verify a token
     * @param {jwt} token:string
     * @returns {boolean}
     */
    const verifyToken = async (token : string) => {
        try {
            const response = await axios.post(VERIFY_URL,
                JSON.stringify({ token: token }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            ); 
            const success : boolean = response?.data?.isSuccess;
            const verifiedToken = response?.data?.token;

            setEmail(verifiedToken?.UserInfo?.email);
            return success;
        } catch (err) {
            console.log(err)
            return false
        }
    }

    /** @function handleSubmit
     * Form submit function, send request to back end to change the password of the user
     */
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        try {
            const response = await axios.post(RESET_URL,
                JSON.stringify({pwd : pwd, email: email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            ); 
            const success = response?.data;
            if (success) {
                setErrMsg('Success');
            } else {
                setErrMsg('Link sending Failed');
            }
            setPwd('');
            setSuccess(true)
        } catch (err) {
            console.log(err)
            setErrMsg('Link sending Failed, error');
        }
    }
    


    //HTML form
    return (
        <>
            <section className='mainBox'>
                <p className={errMsg ? "mainBox__errmsg" : "mainBox__offscreen"} aria-live="assertive">{errMsg}</p>
                {tokenVerified ? ( <>
                <form onSubmit={handleSubmit}>
                    <h1 className='mainBox__title'>Reset Password</h1>
                    <label htmlFor="reset"></label>
                    <div className='mainBox__inputBox'>
                        <input
                            type="text"
                            id="pwd"
                            autoComplete="off"
                            placeholder='New Password'
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                        />
                    </div>

                    <button type="submit" className='mainBox__btn'>Reset Password</button>

                </form>
                </>) : (
                    <div>
                        <p>Invalid Token</p>
                    </div>
                )}
                
                

            </section>
        </>
    )
}


export default ResetPassword