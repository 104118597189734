/**
 * @module Banner
 * Banner of the website, display the Index and other buttons
 */
import { useSelector } from "react-redux"
import "../styles/navigation.css"
import Index from "./Index"
import { RootState } from ".."
import environmentVariables from '../config/environmentVariables';


function Banner() {
    const user = useSelector((state: RootState) => state.user.userValue)
    const settingsUrl = `${environmentVariables.app.baseUrl}:${environmentVariables.app.frontPort}/settings`;

    return (
        <header className="banner">
            <ul className="banner__ul">
                <div className="banner__imgDiv">
                    <a href="/home" className="banner__img">
                        <img src="/images/logo.png" className="banner__img" />
                    </a>
                </div>
                <Index />
                <div className="banner__info">
                    {user.user ?
                        <img src='/images/profil_icon.png' className="displayPFPPIcon" />
                        :
                        <>
                        </>}
                    <a href={settingsUrl} className="banner__list__element">Mon profil</a>
                </div>
            </ul>
        </header>

    )
}

export default Banner