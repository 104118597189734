import React from 'react'
import Role from '../components/exemples/Role'
import Banner from '../components/Banner'
import "../styles/meta.css"

function RolePage() {
  return (
    <body>
      <Banner/>
      <div className='container'><Role /></div>
      
    </body>
  )
}

export default RolePage
