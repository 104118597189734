import axios from 'axios';
import React, { useState } from 'react'
import Papa from 'papaparse'
import environmentVariables from '../../config/environmentVariables';

/** @module CsvUpload
 * UI to upload a CSV and send its data to the back end
 */

const UPLOAD_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/generateData`;

const CsvUpload = () => {
    const [file, setFile] = useState<File | null>(null);

    /**@function handleFileChange
     * Simple handler to set file after the input of the label changed
     */
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    /** @function parse
     * used to parse the data and send it to the back end
     * @param file 
     */
    const parse = async (file: File) => {
        Papa.parse(file, {
            header: true,
            complete: async (results) => {
                try {
                    // You can write the URL of your server or any other endpoint used for file upload
                    const response = await axios.post(UPLOAD_URL,
                        JSON.stringify({data : results.data}),
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                        }
                    );
                    console.log(response?.data?.err)
                } catch (error) {
                    console.error(error);
                }
                
            }
        })
    }

    const handleUpload = async () => {

        if (file) {
            console.log("Uploading file...");
            await parse(file);
        }
    };

    return (
        <>
            <div>
                <label htmlFor="file" className="sr-only">
                    Choose a file
                </label>
                <input id="file" type="file" accept=".csv" onChange={handleFileChange} />
            </div>
            {file && (
                <section>
                    File details:
                    <ul>
                        <li>Name: {file.name}</li>
                        <li>Type: {file.type}</li>
                        <li>Size: {file.size} bytes</li>
                    </ul>
                </section>
            )}

            {file && <button onClick={handleUpload}>Upload a file</button>}
        </>
    );
};


export default CsvUpload

