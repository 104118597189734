// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  background-color: #C9EDEB;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container--background {
  background-image: url(https://pro.standhop.com/images/background.svg);
}
.container--coloredBackground {
  background-color: rgba(0, 133, 124, 0.5098039216);
  width: 100%;
}
.container--uncenter {
  flex-direction: column;
  align-items: default;
  justify-content: default;
}
.container--row {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.container--row--stretch {
  flex: 1 1;
  overflow: auto;
  align-items: stretch;
  width: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/styles/sass/meta.scss","webpack://./src/styles/meta.css"],"names":[],"mappings":"AAQA;EACI,aAAA;EACA,yBAVc;EAWd,aAAA;EACA,sBAAA;EATA,mBAAA;EACA,uBAAA;ACGJ;ADQI;EACI,qEAAA;ACNR;ADSI;EACI,iDAAA;EACA,WAAA;ACPR;ADUI;EACI,sBAAA;EACA,oBAAA;EACA,wBAAA;ACRR;ADWI;EACI,mBAAA;EA5BJ,mBAAA;EACA,uBAAA;ACoBJ;ADUQ;EACI,SAAA;EACA,cAAA;EACA,oBAAA;EACA,WAAA;ACRZ","sourcesContent":["$LightGreenGait : #C9EDEB;\n\n@mixin center {\n    align-items: center;\n    justify-content: center;\n\n}\n\n.container {\n    display: flex;\n    background-color: $LightGreenGait;\n    height: 100vh;\n    flex-direction: column;\n    @include center;\n\n    &--background {\n        background-image: url(https://pro.standhop.com/images/background.svg);\n    }\n\n    &--coloredBackground {\n        background-color: #00857C82;\n        width: 100%;\n    }\n\n    &--uncenter {\n        flex-direction: column;\n        align-items: default;\n        justify-content: default;\n    }\n\n    &--row {\n        flex-direction: row;\n        @include center;\n\n        &--stretch {\n            flex: 1;\n            overflow: auto;\n            align-items: stretch;\n            width: 100%;\n        }\n    }\n\n}",".container {\n  display: flex;\n  background-color: #C9EDEB;\n  height: 100vh;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.container--background {\n  background-image: url(https://pro.standhop.com/images/background.svg);\n}\n.container--coloredBackground {\n  background-color: rgba(0, 133, 124, 0.5098039216);\n  width: 100%;\n}\n.container--uncenter {\n  flex-direction: column;\n  align-items: default;\n  justify-content: default;\n}\n.container--row {\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n.container--row--stretch {\n  flex: 1;\n  overflow: auto;\n  align-items: stretch;\n  width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
