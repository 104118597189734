/** @module RequireAuth
 * Authorization component, used to verify if user has the required roles with jwt
 * @param {any} requiredRoles:Array<number>
 * @param {any} user:any
 * @returns {any}
 */

import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "..";
import { jwtDecode } from "jwt-decode";

//TODO : transfer this code to models
type tokenType = {
    exp: 0,
    UserInfo: {
        username: "userId",
        roles: [0]
    }
};

type RolesProps = {
    roles: Array<number>
}


/**
 * @function Auth
 * Get the roles of the user from its token and compare it to the required Roles param
 * @param {any} requiredRoles:Array<number>
 * @param {any} user:any
 * @returns {any}
 */
export function Auth(requiredRoles: Array<number>, user: any) {

    const token = user?.accessToken
        ? jwtDecode<tokenType>(user.accessToken)
        : undefined;

    const roles = token?.UserInfo.roles || [];

    console.log(roles);
    return (
        roles.find(role => requiredRoles?.includes(role)))
}


//main function
function RequireAuth(props: RolesProps) {
    const location = useLocation();
    const user = useSelector((state: RootState) => state.user.userValue);

    return (
        Auth(props.roles, user)
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />

    );
}

export default RequireAuth